import $ from 'jquery';
import { DirectUpload } from "@rails/activestorage";

const formatProgress = (event) => {
  if (!event.lengthComputable) return null;

  const max = event.total;
  const current = event.loaded;
  let pct = Math.round((current * 100) / max);

  if (pct >= 100) pct = 100;

  return pct;
};

const notify = (deferred, event) => {
  const progress = formatProgress(event);

  if (progress) {
    deferred.notify({ event, progress });
  }
};

const perform = (file, url, defer = $.Deferred()) => {
  const progress = {
    directUploadWillStoreFileWithXHR: request => {
      request.upload.addEventListener("progress", notify.bind(null, defer), false)
    }
  }
  const upload = new DirectUpload(file, url, progress);

  upload.create((error, blob) => {
    if (error) {
      defer.reject(error);

      return;
    }

    defer.resolve(blob);
  })

  return defer;
};

export default { perform };
